<template>
  <div>
    <b-card
      title="รายการฝากเงิน"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <!-- <b-col md="6">
              <b-form-group
                label="บัญชี"
                label-for="mc-first-name"
              >
                <v-select
                  v-model="tableQuery.bankaccount"
                  label="title"
                  :options="bankaccounts"
                >
                  <template #option="data">
                    <span>{{ data.bank }} {{ data.acc_no }} {{ data.acc_name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group
                label="วันที่รายการ"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
          </b-row>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="fetchSMS"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row>
      <b-col md="12">
        <b-card
          title="ข้อความ SMS ทั้งหมด"
        >
          <b-table
            ref="smsTable"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="tableQuery.size"
            :current-page="tableQuery.page"
            :items="fetchSMS"
            :fields="statementsTableFields"
          >
            <template #cell(message)="data">
              <div>
                {{ `${data.item.message}` }}
              </div>
            </template>
            <template #cell(amount)="data">
              <div :style="`text-align: right; ${(data.item.tx_type === 'OUT' ? 'color: red;':'')}`">
                {{ `${(data.item.tx_type === 'OUT' ? '-':'+')}${numberWithCommas(data.item.amount)}` }}
              </div>
            </template>
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="tableQuery.size"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="tableQuery.page"
                :total-rows="totalRows"
                :per-page="tableQuery.size"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BButton, BCardBody, BRow, BCol, BForm, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    // BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankaccounts: [],
      statementItems: [],
      isBusy: false,
      refRecheck: null,
      pageOptions: [100, 200, 500, 1000],
      totalRows: 1,
      tableQuery: {
        page: 1,
        size: 100,
        startdate: moment().format('YYYY-MM-DD 00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statementsTableFields: [
        { key: 'created_at', label: 'วันที่', formatter: val => moment(val).format('DD/MM HH:mm') },
        { key: 'bank', label: 'จาก' },
        { key: 'message', label: 'ข้อความ' },
        // { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        // { key: 'match_user', label: 'รหัสจับคู่' },
        // { key: 'tx_acc', label: 'บัญชีฝาก' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchBankAccounts()
    this.$refs.smsTable.refresh()
  },
  methods: {
    clearSearch() {
      this.tableQuery = {
        page: 1,
        size: 100,
      }
      this.statementItems = []
    },
    async matchUser(bankTransferData) {
      console.log(bankTransferData)
    },
    async fetchSMS(ctx) {
      // if (this.tableQuery.bankaccount === null)
      //   return
      // this.isBusy = true
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }
      const { data: { sms } } = await this.$http.get(`sms-messages?${query}`)
      this.statementItems = sms.data
      this.totalRows = sms.meta.total

      return this.statementItems
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts')
      this.bankaccounts = _bankaccounts.bankaccounts.data.map(i => ({ ...i, title: `${i.bank} ${i.acc_no} ${i.acc_name}` }))
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'DEPOSIT-TMW', acc_name: 'ฝากทรูมันนี่',
      // })
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'WITHDRAW-TMW', acc_name: 'ถอนทรูมันนี่', acc_type: 'OUT', queue_name: 'TMW',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deposit-manual', acc_name: 'ฝากมือ',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'withdraw-manual', acc_name: 'ถอนมือ', acc_type: 'OUT', queue_name: 'M',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deduction', acc_name: 'ตัดเครดิต/ยึดเครดิต', acc_type: 'DEDUCTION',
      // })
      // this.bankaccounts
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
